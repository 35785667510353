import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Melanoma from "../../components/landingPage/Melanoma";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ProcedureDetails from "../../components/landingPage/ProcedureDetails";
import WhyUs from "../../components/landingPage/WhyUs";
import ButtonDrw from "../../components/landingPage/ButtonDrw";
import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";

const GTM_LOCATION = "szczecin-dermatoskopowe";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";

const REVIEWS = [
  {
    author: "Marek B.",
    city: "Szczecin",
    review:
      "Byłem tu na wizycie. Pani dermatolog skrupulatnie zapoznała się z moim problemem i wykonała badanie wideodermatoskopowe -  okazało się, że należy pieprzyk usunąć.",
  },
  {
    author: "Ala S.",
    city: "Szczecin",
    review:
      "Byłam na badaniu znamion. Udało się znaleźć niepokojące znamię na plecach, które okazało się być czerniakiem! Dziękuję Pani doktor za precyzyjne badanie! Teraz chodze regularnie na kontrole ze swoja rodziną.",
  },
  {
    author: "Aron S.",
    city: "Szczecin",
    review:
      "Jesteście najlepszym miejscem w Szczecinie do zbadania zmian skórnych. Wszyscy moi pracownicy, którym wykupiłem pakiet badań zostali bardzo solidnie przebadani, są zadowoleni. Na pewno wrócimy na kolejne kontrole.",
  },
];

const PRICES = [
  {
    isOpened: true,
    categoryName: "Badanie znamion - wideodermatoskopia",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="dd0df680-be59-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "450",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła ",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "350",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="24ff2b30-be5a-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "zabiegi chirurgiczne są wykonywane u pacjentów pełnoletnich",
      },
      {
        price: "550",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "330",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "650",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "380",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Laserowe usuwanie zmian skórnych",
    categorySub: "m.in brodawki, włókniaki, kurzajki",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="673eb3d0-be5a-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "380",
        label: "usunięcie 1 zmiany skórnej do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "70",
        label:
          "*usunięcie dodatkowej zmiany skórnej w trakcie tej samej wizyty do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "430",
        label:
          "usunięcie 1 zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "430",
        label:
          "usunięcie 1 brodawki wirusowej - kurzajki",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej brodawki wirusowej - kurzajki w trakcie tej samej wizyty",
          sub: "w przypadku rozległych zmian o liczbie zabiegów decyduje lekarz",
      },
      {
        price: "200",
        label: "konsultacja lekarska",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    items: [
      {
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_ONKOLOGIA}`}
            data-apptnow-widget="3c785070-0c57-11ee-b8f3-a124fcec387c"
          />
        ),
        price: "300",
        label: "konsultacja onkologiczna - nowotwory skóry",
        
      },
      {
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
            data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
          />
        ),
        price: "200",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje dermatologiczne",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_DERMATOLOGIA}`}
        data-apptnow-widget="ae3b4f50-be5a-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "250",
        label: "konsultacja dermatologiczna",
        sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
      },
    ],
  },
];

const OFFERS = [
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Dermatoskopowe badanie wszystkich znamion na skórze w formie wideodermatoskopii z mapowaniem zmian wytypowanych przez lekarza do obserwacji oraz archiwizacją wykonanych zdjęć.",
      link:"#cennik",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Chirurgiczne usuwanie podejrzanych pieprzyków wytypowanych przez lekarza w trakcie badania wideodermatoskopowego z wykonaniem histopatologicznej oceny usuniętej zmiany skórnej. Nieinwazyjne usuwanie łagodnych znamion laserem Co2 lub metodą krioterapii.",
      link:"/szczecin/usuwanie-znamion-pieprzykow",
  },
  {
    type: "niechirurgiczne",
    title: "Zabiegi laserowe",
    desc:
      "Laserowe usuwanie pieprzyków jest zarezerwowane dla łagodnych zmian skórnych. Lekarz po wykonaniu badania wideodermatoskopowego wszystkich znamion kwalifikuje znamiona do odpowiedniej metody zabiegowej.",
      link:"/szczecin/laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek",
  },
];

const INFOS = [
  {
    title: "Ile trwa badanie?",
    desc:
      "Badanie wszystkich znamion - 30-40 min. Badanie kontrolne znamion wyznaczonych przez lekarza - 15-20 min.",
  },
  {
    title: "Jak się przygotować do badania?",
    desc:
      "Przed badaniem należy wykonać demakijaż oraz skrócić owłosienia w miejscach, w których znajdują się znamiona, celem poprawy jakości obrazu dermatoskopowego.",
  },
  {
    title: "Jakim urządzeniem badamy?",
    desc:
      "Pacjent badany jest za pomocą nowoczesnego sprzętu - wideodermatoskopu Fotofinder Portable Medicam 1000 Full HD",
  },
];

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};
const CtaCennik = () => {
  return (
    <AnchorLink href="#cennik" title="Cennik">
      Ile to kosztuje?
    </AnchorLink>
  );
};

const SzczecinBadanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title: "Czy mogę zbadać tylko jedno niepokojące znamię?",
      text:
        "W naszym ośrodku lekarz ogląda całe ciało pacjenta, a nie tylko niepokojące znamiona. Bardzo często zdarza się, że pacjenta martwi niegroźna brodawka łojotokowa, która być może nieładnie wygląda, ale nie stwarza żadnego ryzyka onkologicznego. Po dokładnych oględzinach wykonanych przez lekarza, zdarza się, że niepokojące znamię czy nawet rak skóry ukrywa się wśród niepozornych pieprzyków na plecach, które były niejednokrotnie wystawiane na działanie promieniowania UV. Prawidłowo wykonane profilaktyczne badanie wideodermatoskopowe to badanie całego ciała!",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Moje znamię okazało się być czerniakiem - co mam teraz zrobić?",
      text:
        "O zaleceniach odnośnie dalszego postępowania pacjent dowiaduje się od lekarza, który wydaje mu wynik badania histopatologicznego - zwykle jest nim chirurg, który usuwał dane znamię. Zazwyczaj pacjent zostaje skierowany do ośrodka onkologicznego, gdyż dalsze postępowanie uzależnione jest od stopnia zaawansowania usuniętej zmiany. Z naszej strony oferujemy fachowe wsparcie i pozostajemy w stałym kontakcie z naszymi Pacjentami.",
    },
  ];

  return (
    <Layout constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Centrum Badania Znamion w Szczecinie - Dermatoskopia - twojeznamiona.pl"
        description="Szczecin - kompleksowa diagnostyka znamion. Badamy wszystkie pieprzyki na ciele. Wideodermatoskopia z mapowaniem. Dlaczego dermatoskopia jest najlepszą metodą diagnostyki zmian skórnych? Ile to kosztuje - cennik. Dermatologia."
        keywords="badanie znamion, szczecin, dermatoskopia"
      />
      <Hero
        h1="Szczecińskie Centrum Dermatoskopowego Badania Znamion"
        oneLiner="Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        oneLinerMore="Upewnij się, że czerniak nie ukrywa się w jednym z niepozornych pieprzyków na Twojej skórze."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
      />
      <Offer className="home_offer" data={OFFERS} />
      <Melanoma className="home_melanoma" />
      <WhyUs className="home_why_us with_no_padding_top" />
      <PriceList className="home_price_list" data={PRICES} ifProgramProfilaktykiAvailable/>

      <Contact className="home_contact" where="w Szczecinie" city="Szczecin" />
      <Location
        className="home_location"
        coords={{
          lat: 53.427594092878905,
          lng: 14.53215226497938,
        }}
        googleMarker={{
          data: ["ul. Pocztowa 41a/29", "70-356 Szczecin"],
          link: "https://g.page/twoje-znamiona-szczecin?share",
        }}
        additionalDetails="Ośrodek znajduje się przy ul. Pocztowej 41a/29 w bliskiej okolicy Wydziału Nauk Ścisłych i Przyrodniczych Uniwersytetu Szczecińskiego. Wejście do ośrodka w kamienicy, naprzeciw sklep Żabka."
        locationInfo={["Centrum Medyczne Medify.me", "ul. Pocztowa 41a/29", "70-356 Szczecin"]}
        hoursInfo={["pon. - pt.: 09:00⁃20:00"]}
        transportationInfo={[
          "Najbliższe przystanki:",
          "Felczaka, Bohaterów Warszawy, Wawrzyniaka",
        ]}
      />
      <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="szczecin"
        imageTitlePostfix="w Szczecinie"
      />

      <Reviews className="home_reviews" data={REVIEWS} />

      <ProcedureDetails
        className="home_two_col_section_right_img"
        h2="Przebieg badania wideodermatoskopowego w Szczecinie"
      />
      <ImportantInfo className="home_important_info" data={INFOS} />
      <Dermoscopy
        className="home_two_col_section_right_img with_no_padding_top"
        reverse={false}
      />
      <Videodermoscopy className="home_two_col_section_left_img" />
      <RiskGroup className="home_risk_group" />
      <Surgery className="home_two_col_section_left_img" reverse={false} />
      <Faq className="home_faq" data={FAQS} />
      <Abcde className="home_abcde" />
      <Oncology className="home_two_col_section_left_img with_no_padding_top with_no_padding_bottom" />
      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: { relativePath: { regex: "/szczecin1.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: { relativePath: { regex: "/szczecin2.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: { relativePath: { regex: "/szczecin3.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq2.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-2-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <SzczecinBadanieZnamionPage
        graph={{
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            // data.ImageGallery3.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
